import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"

import storage from "redux-persist/lib/storage" // or sessionStorage for session storage

import user from "../redux/slice/user"
import branch from "../redux/slice/branch"
import form from "../redux/slice/form"
import modals from "../redux/slice/modals"
import common from "../redux/slice/common"
import fav from "../redux/slice/fav"
import propertySearch from "../redux/slice/property-search"
const persistConfig = {
  blacklist: ["property-search"],
  key: "root",
  storage,
}

const rootReducer = combineReducers({
  user: user,
  branch: branch,
  form: form,
  modals: modals,
  common: common,
  fav: fav,
  propertySearch: propertySearch,
})

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
})

// Create the persistor to persist and rehydrate the store
const persistor = persistStore(store)

export { store, persistor }
