import React from "react"
import { Provider } from "react-redux"
import { store, persistor } from "./src/state/createStore"
import { PersistGate } from "redux-persist/integration/react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {() => element}
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    </>
  )
}
