exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-favourites-js": () => import("./../../../src/pages/favourites.js" /* webpackChunkName: "component---src-pages-favourites-js" */),
  "component---src-pages-p-property-ref-js": () => import("./../../../src/pages/p/[propertyRef].js" /* webpackChunkName: "component---src-pages-p-property-ref-js" */),
  "component---src-pages-property-property-id-js": () => import("./../../../src/pages/property/[property.Id].js" /* webpackChunkName: "component---src-pages-property-property-id-js" */),
  "component---src-pages-property-search-js": () => import("./../../../src/pages/property-search.js" /* webpackChunkName: "component---src-pages-property-search-js" */),
  "component---src-pages-ssr-js": () => import("./../../../src/pages/ssr.js" /* webpackChunkName: "component---src-pages-ssr-js" */),
  "component---src-templates-area-js": () => import("./../../../src/templates/area.js" /* webpackChunkName: "component---src-templates-area-js" */),
  "component---src-templates-branch-js": () => import("./../../../src/templates/branch.js" /* webpackChunkName: "component---src-templates-branch-js" */),
  "component---src-templates-development-js": () => import("./../../../src/templates/development.js" /* webpackChunkName: "component---src-templates-development-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "slice---src-slices-area-with-filter-tsx": () => import("./../../../src/slices/area-with-filter.tsx" /* webpackChunkName: "slice---src-slices-area-with-filter-tsx" */),
  "slice---src-slices-development-js": () => import("./../../../src/slices/development.js" /* webpackChunkName: "slice---src-slices-development-js" */),
  "slice---src-slices-faq-tsx": () => import("./../../../src/slices/faq.tsx" /* webpackChunkName: "slice---src-slices-faq-tsx" */),
  "slice---src-slices-footer-tsx": () => import("./../../../src/slices/footer.tsx" /* webpackChunkName: "slice---src-slices-footer-tsx" */),
  "slice---src-slices-hero-large-tsx": () => import("./../../../src/slices/hero-large.tsx" /* webpackChunkName: "slice---src-slices-hero-large-tsx" */),
  "slice---src-slices-page-link-tsx": () => import("./../../../src/slices/page-link.tsx" /* webpackChunkName: "slice---src-slices-page-link-tsx" */),
  "slice---src-slices-process-tsx": () => import("./../../../src/slices/process.tsx" /* webpackChunkName: "slice---src-slices-process-tsx" */),
  "slice---src-slices-slice-menu-js": () => import("./../../../src/slices/slice-menu.js" /* webpackChunkName: "slice---src-slices-slice-menu-js" */),
  "slice---src-slices-testimonial-tsx": () => import("./../../../src/slices/testimonial.tsx" /* webpackChunkName: "slice---src-slices-testimonial-tsx" */)
}

