import { createSlice } from "@reduxjs/toolkit"

const propertySearchSlice = createSlice({
  name: "property-search",
  initialState: {
    data: {},
    settings: {
      department: "residential-sales",
      page: 1,
      radius: 3,
      orderby: "price-desc",
    },
  },
  reducers: {
    updateSearchState: (state, action) => {
      console.log("REDUX: UPDATE PROPERY SEARCH STATE", action.payload)
      state.settings = action.payload
    },
  },
})

export const { updateSearchState } = propertySearchSlice.actions
export default propertySearchSlice.reducer
