import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const modals = createSlice({
  name: "modals",
  initialState: {
    personalizeFormShown: true,
  },
  reducers: {
    togglePersonalize: (state, action) => {
      console.log("MODALS ACTION", action)
      state.personalizeFormShown = action.payload
    },
  },
})

export const { togglePersonalize } = modals.actions

export default modals.reducer
