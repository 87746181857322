import { createSlice } from "@reduxjs/toolkit"
import { getLocalStorage } from "../../components/helper/localStorageHelper"

const fav = createSlice({
  name: "fav",
  initialState: {
    id:
      typeof window != "undefined" &&
      getLocalStorage("fav") &&
      getLocalStorage("fav")?.value
        ? getLocalStorage("fav").value
        : [],
  },
  reducers: {
    updateFavPropeties: (state, action) => {
      const { id } = action.payload
      state.id = id
    },
  },
})

export const { updateFavPropeties } = fav.actions

export default fav.reducer
