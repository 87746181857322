const getLocalStorage = name => {
  let item = localStorage.getItem(name)

  // if the item doesn't exist, return null
  if (!item) return null
  let result = JSON.parse(item)
  // let data = localStorage.getItem(name);

  console.log(result && !result?.expiry, "expiry")
  if (result && !result?.expiry) {
    localStorage.removeItem(name)
    return null
  }
  // compare the expiry time of the item with the current time
  if (result.expiry && Date.now() > result.expiry) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(name)

    return null
  }

  return result
}

const setLocalStorage = (name, value) => {
  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)

  let item = {
    value: value,
    expiry: tomorrow,
  }

  // if(typeof window != "undefined"){
  localStorage.setItem(name, JSON.stringify(item))
  // }
}

export { getLocalStorage, setLocalStorage }
