/**
 * Get distance b/w two cordinates.
 * @param {*} lat1
 * @param {*} lng1
 * @param {*} lat2
 * @param {*} lng2
 * @returns Integer
 */
const getDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
): number => {
  const R = 3958.8 // Earth radius in miles
  const phi1 = (lat1 * Math.PI) / 180
  const phi2 = (lat2 * Math.PI) / 180
  const dphi = ((lat2 - lat1) * Math.PI) / 180
  const dlambda = ((lng2 - lng1) * Math.PI) / 180
  const a =
    Math.sin(dphi / 2) * Math.sin(dphi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(dlambda / 2) *
      Math.sin(dlambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  // const d = Math.floor(R * c);
  const d = R * c
  return d
}

export default getDistance
